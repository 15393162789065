/* * {
    box-sizing: border-box;
  }
  
  body {
    color: #383f4d;
    line-height: 1.5;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
      sans-serif;
  } */
  
  /* 
  =================
  Table
  =====================
  */
  
  .table_container {
    max-width: 750px;
    /* max-height: 500px; */
    margin: 0 auto;
    overflow: auto;
    
  }
  
  .table {
    width: 100%;
    border-spacing: 0;
  }
  
  caption {
    display: none;
  }
  
  .table th {
    background: #fff;
    padding: 8px 20px;
    border-bottom: 1px solid #1a1a1a;
    font-weight: bold;
    text-align: left;
    color: black;
    font-size: 16px;
  }
  
  .table th.up {
    background-image: url("../../components/resources/images/up_arrow.png");
  }
  .table th.down {
    background-image: url("../../components/resources/images/down_arrow.png");
  }
  .table th.default {
    background-image: url("../../components/resources/images/default.png");
  }

  th.up,
  th.default,
  th.down {
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center right;
  }
  
  .table td {
    border-top: 1px solid #ddd;
    padding: 8px 20px;
    color: black;
    font-size: 14px;
  }

  #hover-styling:hover {
    background-color: var(--indigo);
    color: white;
  }

  .extrapaperstyling {
    border-top: 1px solid #ddd;
    color: black;
    font-size: 14px;
    text-align: center;
  }

  .table a {
    border-top: 1px solid #ddd;
    padding: 8px 20px;
    color: black;
    font-size: 14px;
  }

  .table tbody tr:first-child {
    border-top: none;
  }
  
  .table tbody tr:nth-child(n) {
    background: #eff0f0;
  }
  
  .table tbody tr:nth-child(2n) {
    background: #fff;
  }

  .paperlink {
    display: flex;
    margin: auto;
    
  }