.dropzone {

    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    width: 60%;
    margin: auto;
    color: black;
  
  }

  .dropzone-content {
    color: black;
  }