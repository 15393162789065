.form-box{
    min-width: 100%;
    margin: 0vw;
    padding: 5vw;
    background-color: white;
    position:relative;
    box-shadow: 0 1px 4px rgba(0,0,0,.16);
    border-radius: 1.6rem;
}

.field1{
    position:relative;
}

.field2{
    position:relative;
}
/******** h5 ********/

.form-box h5{
    font-size: 35px ;
    text-align: left;
    color: black;
    margin: 0 0 10vh 0;
}

#title-submit h2{
    font-size: 35px ;
    text-align: center;
    color: white;
    margin: 0 0 5vh 0;
}



/******** label ********/

.form-box label{
    display: block;
    text-align: left;
    font-size: 20px;
    margin: 0 0 2vh 0;
}



/******** input textarea ********/

.form-box input, textarea{
    display: block;
    width: 100%;
    padding: .5rem .8rem .5rem .8rem;
    margin: .9vw 0;
    border:1px #CCC solid;
    border-radius: 5px;
    font-size: 20px;
}

/******** submit button  ********/
#submitBtn{
    border: 0; 
    background-color: #0096a9;
    padding: .5rem;
    color: #FFFF;
    margin: 1rem 1rem;
    width: 100%;
    text-transform: uppercase;
    cursor: pointer;
    /* change the mouse corsor to finger pointer */
    transition: 1s background ease;
    font-size: 20px;
    border-radius:0.8rem;
    
}


/* submit hover over  */
#submitBtn:hover{
    background-color: #953683;
    color: #FFF;
    font-size: 20px;
}

.downloadBtn {
    width: 50%;
    display: flex;
}

.buttonContainer{
    display:flex;
}