.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: '100vh';
  width: 100%;
  background-color:white;
}

.wrapper-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: '100vh';
  width: 100%;
  margin-bottom: 50px;
}

.container {
  /* display: flex; */
  width: 90%;
  margin: 5%;
  
}

.container_raise {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
}

.container_box {
  /* display: flex; */
  width: 90%;
  margin: 5%;
  
}
