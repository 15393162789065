.benchmark-features {
    /* responsive */
  
    padding: 3.2rem 6.4rem;
  }
  
  .benchmark-features__title {
    color: var(--black);
    font-weight: 600;
    text-align: center;
  
    /* responsive */
  
    margin-top: 0;
    margin-bottom: 4.8rem;
  
    font-size: 3.2rem;
    line-height: 3.2rem;
  }
  
  .benchmark-features__grid {
    display: grid;
  
    /* responsive */
  
    grid-template-columns: 1fr;
    column-gap: 6.4rem;
    row-gap: 2.4rem;
  }
  
  .benchmark-feature {
    display: block;
  
    background-color: var(--white);
  
    color: var(--black);
  
    cursor: pointer;
  
    transition: all 0.3s ease-in-out 0s;
  
    /* responsive */
  
    padding: 4.8rem;
  
    border-radius: 1.6rem;
  }
  
  .benchmark-feature:hover {
    transform: scale(1.03);
  }
  
  .benchmark-feature__headline {
    display: flex;
    align-items: center;
  
    margin-top: 0;
  
    color: var(--black);
    font-weight: 600;
    letter-spacing: -0.05rem;
  
    /* responsive */
  
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
  
  .benchmark-feature__icon {
    /* responsive */
  
    margin-right: 1.6rem;
  }
  
  .benchmark-feature:hover .benchmark-feature__headline {
    text-decoration: underline solid 2px var(--black);
    text-underline-offset: 2px;
  
    transition: all 0.3s ease-in-out 0s;
  }
  
  .benchmark-feature__description {
    margin: 0;
  
    color: rgb(36, 36, 36);
    font-weight: 500;
  
    opacity: 0.7;
  
    /* responsive */
  
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  
  @media only screen and (max-width: 900px) {
    .benchmark-features__grid {
      grid-template-columns: 1fr;
      row-gap: 3.2rem;
    }
  
    .benchmark-feature {
      padding: 3.2rem;
      border-radius: 0.8rem;
    }
  
    .benchmark-feature__headline {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  
    .benchmark-feature__icon {
      height: 3.6rem;
  
      margin-right: 1.6rem;
    }
  
    .benchmark-feature__description {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .benchmark-features {
      padding: 1.6rem;
    }
  
    .benchmark-features__title {
      font-size: 2rem;
      line-height: 2.8rem;
      margin-bottom: 2.4rem;
    }
  
    .benchmark-features__grid {
      grid-template-columns: 1fr;
      row-gap: 1.6rem;
    }
  
    .benchmark-feature {
      padding: 3.2rem;
      border-radius: 0.8rem;
    }
  
    .benchmark-feature__headline {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  
    .benchmark-feature__icon {
      height: 3.6rem;
  
      margin-right: 1.6rem;
    }
  
    .benchmark-feature__description {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }
  